import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { LogoutComponent } from 'src/api-authorization/logout/logout.component';
import { UserSectionComponent } from './components';

const routes: Routes = [
  {
    path: "", component: UserSectionComponent, canActivate: [AuthorizeGuard],
  },
  { path: 'logout', component: LogoutComponent },
  // otherwise redirect to home
  // { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
