import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MaterialImportModule } from '../../material/materialimport.module';
import { UserService } from '../services/user.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonComponentModule } from '../common/common-component.module';
import { DataService } from '../services';
import {
  UserAddComponent, 
  UserAddPanelComponent,
  UserEditComponent, 
  UserEditErrorComponent, 
  UserEditPanelComponent, 
  UsersListComponent,
  UserViewComponent
} from './components';
import { UserValidator } from '../models/user-validator';

@NgModule({
  declarations: [
    UsersListComponent,
    UserEditComponent,
    UserEditPanelComponent,
    UserViewComponent,
    UserAddComponent,
    UserAddPanelComponent,
    UserEditErrorComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialImportModule,
    CommonComponentModule
  ],
  exports: [
    UsersListComponent,
    UserEditComponent,
    UserEditPanelComponent,
    UserViewComponent,
    UserAddComponent,
    UserAddPanelComponent,
    UserEditErrorComponent
  ],
  providers: [
    DataService,
    UserService,
    UserValidator
  ]
})
export class UserModule {
}
