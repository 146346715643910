<div *ngIf="user">
    <div class="user-view-form">
        <mat-form-field>
            <input matInput placeholder="Username" [value]="user.username" readonly>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="First name" [value]="user.firstName" readonly>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Last name" [value]="user.lastName" readonly>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Work Phone" [value]="user.workPhone" readonly>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Mobile Phone" [value]="user.mobilePhone" readonly>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="E-mail" [value]="user.email" readonly>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Menu role" [(ngModel)]="selectedMenuRole" readonly>
        </mat-form-field>

        <mat-checkbox [checked]="isComplianceUser" disabled>Compliance admin rights</mat-checkbox>


    </div>

    <mat-divider></mat-divider>

    <br/>

    <div class="meta-data-container">
        <mat-form-field>
            <input placeholder="Created by" matInput [value]="createdBy ? createdBy : ''" disabled>
        </mat-form-field>

        <mat-form-field>
            <input placeholder="Changed by" matInput [value]="changedBy ? changedBy : ''" disabled>
        </mat-form-field>
        <br />

        <mat-form-field>
            <input matInput placeholder="Version" [value]="user.version" disabled>
        </mat-form-field>

        <mat-checkbox [checked]="user.inactive" disabled>Inactive user</mat-checkbox>

    </div>

    <br />

    <mat-divider class="form-divider"></mat-divider>
    <br />

</div>