<mat-expansion-panel *ngIf="dataSource && dataSource.data" [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title [innerHTML]="hasWriterAccess ? 'Chosen Sectors': 'Your sectors'">
            Chosen sectors
        </mat-panel-title>
        <mat-panel-description>
            <span class="toolbar-spacer"></span>
        </mat-panel-description>
    </mat-expansion-panel-header>

        
    <ng-container *ngIf="currentUser.hasWriterAccess && selectedSectorsCopy.length > 0">
        <div class="selected-sectors-button-row">
            <button type="button" mat-raised-button  color="warn" (click)="clear()">Clear</button>
            <span class="toolbar-spacer"></span>
            <button type="button" mat-raised-button (click)="undo()" [disabled]="!selectedSectorsCopy">Undo</button>
            <button type="button" mat-raised-button  color="primary" (click)="save()">Save</button>
        </div>
    
        <br />
        <mat-divider></mat-divider>
        <br />
    </ng-container>

    <div class="selected-sector-tree-container">
        <mat-tree [dataSource]="dataSourceSelected" [treeControl]="treeControlSelected" class="sector-tree">
            <mat-tree-node *matTreeNodeDef="let node" [ngStyle]="{'padding-left': node.level * 25 + 'px'}">
                <!-- <button mat-icon-button disabled></button> -->
                <span>{{getNodeText(node)}}</span>
                <span class="toolbar-spacer"></span>
                <button *ngIf="hasWriterAccess && node.roleId > 0" mat-icon-button (click)="removeSectorAccess(node.id)">
                    <mat-icon aria-hidden="false" aria-label="Remove sector">remove_circle</mat-icon>
                </button>
            </mat-tree-node>

            <!-- This is the tree node template for expandable nodes -->
            <mat-tree-node *matTreeNodeDef="let node; when: hasChild"
                [ngStyle]="{'padding-left': node.level * 25 + 'px'}">
                <span>{{getNodeText(node)}}</span>
                <span class="toolbar-spacer"></span>
                <button *ngIf="hasWriterAccess && node.level > 0 && node.roleId > 0" mat-icon-button
                    (click)="removeSectorAccess(node.id)">
                    <mat-icon aria-hidden="false" aria-label="Remove sector">remove_circle</mat-icon>
                </button>
            </mat-tree-node>
        </mat-tree>
    </div>

    <br />
    <div *ngIf="selectedSectors.length == 0">
        <mat-label>No sectors has been selected</mat-label>
    </div>

</mat-expansion-panel>