import { Component, OnInit } from '@angular/core';
import { CodeName, UserEntity, UserDetail, UserAction, ComplianceUser } from 'src/app/models/user.model';
import { BehaviorSubject } from 'rxjs';
import { Globals } from 'src/app/globals';
import { UserService } from 'src/app/services';
import { ConfirmDialog } from 'src/app/common/components';
import { UserBehaviour, DateFormatter } from 'src/app/common';
import { UpdateUserRequest, UpdateComplianceUserRequest, DeleteComplianceUserRequest } from 'src/app/models/user-update-models';
import { UserEditForm } from 'src/app/models/form-models';
import { UserValidator } from 'src/app/models/user-validator';
import { Logger } from 'src/app/_helpers';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { UserInviteModel } from "src/app/models/user-invite-model";
import { UserUpdateModel } from "src/app/models/user-update-model";
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'user-edit',
    templateUrl: './user-edit.component.html',
    styleUrls: ['./user-edit.component.sass']
})
export class UserEditComponent implements OnInit {

    selectedItemSubject = new BehaviorSubject<CodeName>({ id: 0, name: '' });
    sendEmailInvite = new FormControl(false);
    menuRoles: CodeName[];
    currentUser: UserDetail;
    user: UserEntity;
    complianceUser: ComplianceUser;
    complianceChanged: boolean = false;
    username: string;
    form = new UserEditForm();

    public get createdBy(): string {
        if (!(this.user && this.user.createdBy)) return '';

        return `${this.user.createdBy} ${DateFormatter.ToShortHourMinutes(this.user.createdDate)}`
    }

    public get changedBy(): string {
        if (!this.user) return '';

        return `${this.user.changedBy} ${DateFormatter.ToShortHourMinutes(this.user.changedDate)}`
    }

    public get readOnlyAttr(): string | null {
        return this.isMyself() ? 'true' : null
    }

    constructor(
        private userService: UserService,
        private auth: AuthorizeService,
        private confirmDialog: ConfirmDialog,
        private globals: Globals,
        private userBehaviour: UserBehaviour,
        private logger: Logger) { }

    public get roleName(): string {
        const roleId = this.form.controls.menuRole.value;
        if (!this.menuRoles || this.menuRoles.length === 0) return '';
        if (!roleId) return '';
        const role = this.menuRoles.find(r => this.form.controls.menuRole.value == r.id.toString())
        return role ? role.name : '';
    }

    isMyself(): boolean {
        return this.currentUser ? this.currentUser.username === this.username : false;
    }

    ngOnInit(): void {

        this.globals.menuRoles.subscribe(roles => this.menuRoles = roles);

        this.globals.currentUserSubject.subscribe((user: UserDetail) => {
            this.currentUser = user;
        });


        this.userBehaviour.usernameSubject.subscribe((username: string) => {
            if (username && username.length > 0) {
                this.username = username;
                this.getUser();
            }
        });

        this.selectedItemSubject.subscribe(item => this.handleSelectedItem(item));

    }

    getUser() {
        if (this.username && this.username.length > 0) {
            this.userService.getUser(this.username)
                .toPromise()
                .then(user => {
                    this.user = user;
                    this.updateUserModel();
                    this.logger.debug("fetched user", user);
                    this.userBehaviour.userEntitySubject.next(user);

                    this.getComplianceUser();
                });
        }

    }

    getComplianceUser() {
        this.complianceChanged = false;
        this.userService.getComplianceUser(this.user.userId)
            .toPromise()
            .then(user => {
                this.complianceUser = user;
                this.form.controls.compliance.setValue(user && user.id > 0);
                this.logger.debug("complianceUser", user);
            });
    }

    isComplianceUser(): boolean {
        return this.complianceUser && this.complianceUser.id > 0;
    }

    toggleCompliance() {
        this.complianceChanged = !this.complianceChanged;
        this.logger.debug("toggleCompliance", this.complianceChanged);
    }

    reset() {
        this.getUser();

    }

    cancel() {
        this.userBehaviour.userActionSubject.next(UserAction.None);
    }

    updateUserModel() {
        if (this.user) {
            const u = this.user;
            const form = this.form.controls;

            form.firstName.setValue(u.firstName);
            form.lastName.setValue(u.lastName);
            form.workPhone.setValue(u.workPhone);
            form.mobilePhone.setValue(u.mobilePhone);
            form.username.setValue(u.username);
            form.email.setValue(u.email);
            form.inactive.setValue(u.inactive);
            form.menuRole.setValue(u.menuRole.toString());
            this.selectedItemSubject.next({ id: u.menuRole, name: '' });
        }
    }

    userExists(): boolean {
        const username = this.form.controls.username.value;

        if (username == this.username) return false;
        return UserValidator.find(username);
    }

    updateUserFromForm() {
        const form = this.form.controls;
        const u = this.user;
        u.email = form.email.value;
        u.firstName = form.firstName.value;
        u.fullName = form.firstName.value + ' ' + form.lastName.value;
        u.lastName = form.lastName.value;
        u.mobilePhone = form.mobilePhone.value;
        u.workPhone = form.workPhone.value;
        u.inactive = form.inactive.value;
        u.menuRole = parseInt(form.menuRole.value);
        u.username = form.username.value;
    }

    saveToDb(): void {
        const u = this.user;
        const request: UpdateUserRequest =
        {
            userId: u.userId,
            username: u.username,
            firstName: u.firstName,
            lastName: u.lastName,
            email: u.email,
            workPhone: u.workPhone,
            mobilePhone: u.mobilePhone,
            menuRole: u.menuRole,
            version: u.version,
            inactive: u.inactive
        };

        this.userService.updateUser(request)
            .subscribe(success => {
                if (success) {
                    this.confirmDialog.openSnackBar(`Updated user information for ${this.user.fullName} (${this.user.username})`);
                    this.userBehaviour.userActionSubject.next(UserAction.None);
                    this.userBehaviour.refreshUsersSubject.next(true);
                    this.saveComplianceUserToDb();
                }
                else {
                    this.confirmDialog.openErrorSnackBar(`Could not update user information for ${this.user.fullName} (${this.user.username})`);
                }
                
            });

    }

    syncWithSSO(): void {
        const u_model: UserUpdateModel = {
            Email: this.user.email,
            Roles: [this.roleName],
            SystemId: 'GROW',
          };
        this.userService.syncSSO( u_model, this.auth.getSSOUpdatePath()).subscribe(()  => { },
        _err => {
            this.confirmDialog.openErrorSnackBar(`Could not sync with identityserver: ${this.user.username}`);
        })
    }

    sendInviteEmail(): void{
        const invite_model: UserInviteModel = {
            ActivateLabel: 'Activate account',
            ContactEmail: 'research.online@handelsbanken.se',
            Header: 'Welcome to GROW',
            ReturnUrl: 'https://www.researchonline.se/',
            Subject: 'Welcome to GROW',
            Username: this.user.email,
            ApplicationName: "GROW" // Do not change
          };
        this.userService.sendInviteEmail(invite_model, this.auth.getInviteEmailPath()).subscribe(() => { },
            _err => {
                this.confirmDialog.openErrorSnackBar(`Could not send email to ${this.user.email}`);
            });
    }

    saveComplianceUserToDb() {
        if (!this.complianceChanged) return;

        const compliance = this.form.controls.compliance.value;
        if (compliance) {
            this.updateComplianceUser();
        }
        else {
            this.deleteComplianceUser();
        }
    }

    updateComplianceUser() {
        this.logger.debug("updateComplianceUser");

        let request: UpdateComplianceUserRequest;
        if (this.complianceUser) {
            this.logger.debug("updating compliance user");
            request = {
                userId: this.user.userId,
                createdById: this.complianceUser.createdById,
                version: this.complianceUser.version
            };
        }
        else {
            this.logger.debug("new compliance user");
            request = {
                userId: this.user.userId,
                createdById: this.currentUser.userId,
                version: 0

            }
        }
        this.userService.updateComplianceUser(request).subscribe(success => {
            if (!success) {
                this.confirmDialog.openErrorSnackBar(`Could not update compliance admin rights for ${this.user.fullName} (${this.user.username})`);
            }
        });
    }

    deleteComplianceUser() {
        if (!this.complianceUser) return;

        this.logger.debug("deleteComplianceUser");

        const request: DeleteComplianceUserRequest = {
            userId: this.user.userId
        }

        this.userService.deleteComplianceUser(request).subscribe(success => {
            if (!success) {
                this.confirmDialog.openErrorSnackBar(`Could not remove compliance admin rights for ${this.user.fullName} (${this.user.username})`);
            }
        });

    }

    saveUser(): boolean {
        if (!this.form.valid || (!this.form.form.dirty && this.sendEmailInvite.value !== true)) {
            return;
        }

        const username = this.form.controls.username.value;
        if (username != this.username) {
            this.userService.userExists(username)
                .subscribe((found: boolean) => {
                    if (found) {
                        this.form.controls.username.setErrors({ userexists: true });
                        this.confirmDialog.openError('User exists', `User already exists: ${this.user.username}`);
                    }
                    else {
                        this.updateUserFromForm();
                        this.saveToDb();
                        this.syncWithSSO();
                        if (this.sendEmailInvite.value === true) {
                            this.sendInviteEmail();
                        }
                    }
                });
        }
        else {
            this.updateUserFromForm();
            this.saveToDb();
            this.syncWithSSO();
            if (this.sendEmailInvite.value === true) {
                this.sendInviteEmail();
            }
        }

        return false;

    }

    handleSelectedItem(menuRole: CodeName) {
        const id = menuRole.id;
        if (this.user && id != this.user.menuRole) {
            this.form.controls.menuRole.setValue(id);
            this.form.controls.menuRole.markAsDirty();
            this.user.menuRole = id;
            this.updateUserFromForm();
        }
    }

}

