<form class="form" name="userAddForm" [formGroup]="form.form">
    <div class="user-add-form">

        <mat-form-field>
            <input matInput placeholder="Username" [formControl]="form.controls.username" required>
            <user-edit-error [control]="form.controls.username" name="username"></user-edit-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="First name" [formControl]="form.controls.firstName" required>
            <user-edit-error [control]="form.controls.firstName" name="firstName"></user-edit-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Last name" [formControl]="form.controls.lastName" required>
            <user-edit-error [control]="form.controls.lastName" name="lastName"></user-edit-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Work Phone" [formControl]="form.controls.workPhone" required>
            <user-edit-error [control]="form.controls.workPhone" name="workPhone"></user-edit-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Mobile Phone" [formControl]="form.controls.mobilePhone" required>
            <user-edit-error [control]="form.controls.mobilePhone" name="mobilePhone"></user-edit-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput placeholder="Enter your email" [formControl]="form.controls.email" required>
            <user-edit-error [control]="form.controls.email" name="email"></user-edit-error>
        </mat-form-field>

        <code-name-select *ngIf="selectedItemSubject" [items]="menuRoles" [selectedItemSubject]="selectedItemSubject" label="Menu role">
        </code-name-select>

        <mat-checkbox id="invite" color="primary" [formControl]="sendEmailInvite">Send email invite</mat-checkbox>
    </div>

    <br />

    <mat-divider class="form-divider"></mat-divider>
    <br />


    <div class="user-add-buttons">
        <button type="submit" mat-raised-button (click)="addUser()" color="primary" [disabled]="form.form.dirty || !form.valid ? null: true">Add user</button>
        <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
    </div>

</form>