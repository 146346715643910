import { Injectable } from '@angular/core';
import { UserBehaviour } from '../common/user-behaviour';
import { UserDetail } from './user.model';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Injectable()
export class UserValidator {

  private static users: UserDetail[] = [];

  constructor(userBehaviour: UserBehaviour) {
    userBehaviour.usersSubject.subscribe(users => UserValidator.users = users);
  }

  public static find(name: any): boolean {
    var user = UserValidator.users.find(u => u.username == name);
    return user != null;
  }

  public static getUser(name: string): UserDetail {
    if (name && name.length > 0) {
      const user = UserValidator.users.find(u => u.username == name);
      return user;
    }
    return null;
  }

  public exists(control: AbstractControl): ValidationErrors | null {
    return UserValidator.find(control.value) ? { userexists: { value: control.value } } : null;
  }

  // exists(name: string): ValidatorFn {
  //   return (control: AbstractControl): { [key: string]: any } | null => {
  //     const exists = this._exists(control.value);
  //     return exists ? { 'user exists': { value: control.value } } : null;
  //   };
  // }

}
