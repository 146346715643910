import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from 'rxjs';

import { UserDetail, UserEntity, ComplianceUser } from '../models/user.model';
import { AddUserRequest, UpdateUserRequest, UpdateComplianceUserRequest, DeleteComplianceUserRequest } from '../models/user-update-models';
import { environment } from 'src/environments/environment';
import { UserUpdateModel } from "src/app/models/user-update-model";

@Injectable()
export class UserService {
  get baseUrlGrow() {
    return environment.baseUrlGrowApi;
  }

  constructor(private http: HttpClient) { }

  public getUser(username: string): Observable<UserEntity> {
    return this.http.get<UserEntity>(
      `${this.baseUrlGrow}v1/useradmin/user/${username}`,
      { withCredentials: true }
    );
  }

  public getUsers(): Observable<UserDetail[]> {
    return this.http.get<UserDetail[]>(
      `${this.baseUrlGrow}v1/useradmin/users`,
      { withCredentials: true }
    );
  }

  public getCurrentUser(): Observable<UserDetail | null> {
    return this.http.get<UserDetail>(
      `${this.baseUrlGrow}v1/useradmin/currentuser`,
      { withCredentials: true }
    );

  }

  public addUser(request: AddUserRequest): Observable<UserEntity> {
    return this.http.post<UserEntity>(
      `${this.baseUrlGrow}v1/useradmin/add`, request, { withCredentials: true }
    );
  }

  public updateUser(request: UpdateUserRequest): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.baseUrlGrow}v1/useradmin/update`, request, { withCredentials: true }
    );
  }

  public userExists(username: string): Observable<boolean> {
    return this.http.get<boolean>(
      `${this.baseUrlGrow}v1/useradmin/exists/${username}`,
      { withCredentials: true }
    );

  }

  public getComplianceUser(userId: number): Observable<ComplianceUser> {
    return this.http.get<ComplianceUser>(
      `${this.baseUrlGrow}v1/useradmin/compliance/${userId}`,
      { withCredentials: true }
    );
  }
  
  public updateComplianceUser(request: UpdateComplianceUserRequest): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.baseUrlGrow}v1/useradmin/compliance`, request, { withCredentials: true }
    );
  }
  public deleteComplianceUser(request: DeleteComplianceUserRequest): Observable<boolean> {
    return this.http.post<boolean>(
      `${this.baseUrlGrow}v1/useradmin/compliance/delete`, request, { withCredentials: true }
    );
  }

  public syncSSO(user: UserUpdateModel, apiPath: string): Observable<any>{
    return this.http.post<any>(apiPath, user, {withCredentials: true});
  }
  public sendInviteEmail(invite: any, apiPath: string): Observable<any> {
    return this.http.post<any>(apiPath, invite, {withCredentials: true});
  }
  
}
