import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from 'rxjs';

import { CodeName } from '../models/user.model';
import { environment } from 'src/environments/environment';

@Injectable()
export class DataService {
  get baseUrlGrow() {
    return environment.baseUrlGrowApi;
  }

  constructor(private http: HttpClient) { }

  getMenuRoles(): Observable<CodeName[]> {
    return this.http.get<CodeName[]>(this.baseUrlGrow + "v1/data/menuroles", {
      withCredentials: true
    });

  }

  getSectorRoles(): Observable<CodeName[]> {
    return this.http.get<CodeName[]>(this.baseUrlGrow + "v1/data/sectorroles", {
      withCredentials: true
    });

  }
}
