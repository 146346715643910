<div id="ie" *ngIf="isIE() > 0">You seem to use Internet Explorer! To get the best possibly experience we kindly ask
  you to switch to
  <b>Google Chrome</b> or
  <b>Mozilla Firefox</b>. Thanks
  <br/>&nbsp;
</div>

<mat-card>
  <mat-card-title>
    <img src="./assets/shb-large-logo.svg" width="200" />
    GROW User Admin
  </mat-card-title>
  <mat-card-content>
    This tool makes it possible to admin user details and sector access.
  </mat-card-content>
</mat-card>

<br />
<router-outlet></router-outlet>
