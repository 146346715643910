import { Component, Input, OnInit } from "@angular/core";
import { merge } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { SectorService } from '../../../services/sector.service';
import { UserBehaviour as UserBehaviour } from 'src/app/common/user-behaviour';
import { SectorTreeNode, SectorAccessRole } from 'src/app/models/sector-model';
import { SectorBehaviour } from 'src/app/common/sector-behaviour';
import { Globals } from 'src/app/globals';
import { CodeName } from 'src/app/models/user.model';
import { UserValidator } from 'src/app/models/user-validator';

@Component({
    selector: 'sector-access',
    templateUrl: './sector-access.component.html',
})
export class SectorAccessComponent implements OnInit {
    isLoadingTree: boolean;
    isErrorTree: boolean;
    username: string;
    tree: SectorTreeNode[];
    sectorRoles: CodeName[];

    constructor(private sectorService: SectorService, private userBehaviour: UserBehaviour, private sectorBehaviour: SectorBehaviour,
        private globals: Globals, private userValidator: UserValidator) { }

    ngOnInit() {
        this.globals.sectorRoles.subscribe(roles => this.sectorRoles = roles);

        this.userBehaviour.usernameSubject.subscribe(username => {
            const user = UserValidator.getUser(username);
            if (!user) return;

            this.username = username;

            this.sectorService.getSectorUserByUserId(user.userId).subscribe((sectors: SectorAccessRole[]) => {
                this.sectorBehaviour.sectorAccessRoleSubject.next(sectors);
                this.getSectorTree();
                this.sectorBehaviour.sectorEditSubject.next(null);

             });
        });
    }

    getSectorRoleById(id: number): CodeName {
        let role = this.sectorRoles.find(r => r.id == id);
        return role ? role : null;
    }

    getSectorTree() {

        merge()
            .pipe(
                startWith({}),
                switchMap(() => {
                    this.isLoadingTree = true;
                    return this.sectorService.getSectorTree();
                }),
                map(data => {
                    this.isLoadingTree = false;
                    return data;
                }),
                catchError(() => {
                    this.isLoadingTree = false;
                    this.isErrorTree = true;
                    return [];
                })
            ).subscribe((tree: SectorTreeNode[]) => {
                this.tree = tree;
                this.sectorBehaviour.treeSubject.next(tree);
            });
    }


}