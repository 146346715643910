import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Company, SectorEditNode, SectorCompanyNode } from 'src/app/models/sector-model';
import { UserBehaviour, SectorBehaviour } from 'src/app/common';
import { UserEntity } from 'src/app/models/user.model';
import { SectorService } from 'src/app/services';
import { MatTableDataSource } from '@angular/material/table';
import { Logger } from 'src/app/_helpers';

@Component({
    selector: 'sector-companies',
    templateUrl: './sector-companies.component.html',
    styleUrls: ['./sector-companies.component.sass']
})
export class SectorCompaniesComponent implements OnInit {
    user: UserEntity;
    sectorEditNode: SectorEditNode;
    companies: SectorCompanyNode[];
    displayedColumns: string[] = ['companyName', 'protected', 'roleName']; //'status'
    dataSource: MatTableDataSource<SectorCompanyNode>;

    constructor(
        private globals: Globals,
        private userBehaviour: UserBehaviour,
        private sectorBehaviour: SectorBehaviour,
        private sectorService: SectorService,
        private logger: Logger) {
        this.logger.debug('sector-companies');
    }

    ngOnInit(): void {
        this.userBehaviour.userEntitySubject.subscribe(user => {
            this.user = user;
            if (!user) return;

            this.sectorBehaviour.sectorEditSubject.subscribe(node => {
                this.sectorEditNode = node;
                if (!node) return;
                this.getCompanies();
            });
        });
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    getCompanies() {
        this.logger.debug("getCompanies");
        this.sectorService.getSectorCompanyNodes(this.user.userId, this.sectorEditNode.data)
            .subscribe(companies => {
                this.companies = companies;
                this.dataSource = new MatTableDataSource(this.companies);
            });
    }


}
