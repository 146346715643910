<mat-form-field>
    <mat-label>{{label}}</mat-label>
    <mat-select
        [(ngModel)]="selectedItem" 
        [compareWith]="compareObjects"
        [disabled]="disabled ? 'true': null"
        (selectionChange)="emitSelectedItem()">
        <mat-option value="" selected disabled="true">Choose...</mat-option>
        <mat-option *ngFor="let item of items" [value]="item">
            {{item.name}}
        </mat-option>
    </mat-select>
</mat-form-field>