import { Component, Input, OnInit } from '@angular/core';
import { CodeName } from 'src/app/models/user.model';
import { BehaviorSubject } from 'rxjs';

@Component({
    selector: 'code-name-select',
    templateUrl: './code-name-select.component.html'
    //styleUrls: ['./user-edit.component.css']
})
export class CodeNameSelectComponent implements OnInit {

    @Input()
    label: string;
    @Input()
    items: CodeName[];
    @Input()
    disabled: boolean;

    @Input() selectedItemSubject: BehaviorSubject<CodeName>;

    selectedItem: CodeName;

    ngOnInit(): void {
        if (this.selectedItemSubject) {
            this.selectedItemSubject.subscribe(item => {
                this.selectedItem = item;
            });
        }
    }

    emitSelectedItem() {
        if (this.selectedItemSubject) {
            this.selectedItemSubject.next(this.selectedItem);
        }
    }

    compareObjects(o1: CodeName, o2: CodeName): boolean {
        return o1.id === o2.id;
    }

}
