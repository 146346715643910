export enum MenuRole
{
    None = "None",
    Admin = "Admin",
    Writer = "Writer",
    Nobody = "Nobody",
    SuperAdmin = "SuperAdmin",
    COWG = "COWG"
}

export class UserDetail
{
    userId: number;
    username: string;
    fullName: string;
    email: string;
    workPhone: string;
    mobilePhone: string;
    firstName: string;
    lastName: string;
    menuRole: MenuRole;
    inactive: boolean;
    hasWriterAccess: boolean;
    isSuperAdmin: boolean;
}

export class UserEntity
{
    userId: number;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    workPhone: string;
    mobilePhone: string;
    menuRole: number;
    inactive: boolean;
    
    fullName: string;
    createdBy: string;
    createdDate: Date;
    changedDate: Date;
    changedBy: string;
    version: number;

}

export class ComplianceUser {
    id: number;
    version: number; // CWS User Version
    createdById: number;
    createdDate: Date;
    changedById: number;
    changedDate: Date;
}

export class CodeName
{
    id: number;
    name: string;
}

export enum UserAction
{
    None = "None",
    Edit = "Edit",
    View = "View",
    Add = "Add"
}
