import { CodeName, UserDetail, MenuRole } from './models/user.model';
import { Injectable, isDevMode } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { DataService, UserService } from './services';
import { Logger } from './_helpers';

@Injectable()
export class Globals {
    private debugRole: DebugRole = DebugRole.None;

    public menuRoles = new Observable<CodeName[]>();
    public sectorRoles = new Observable<CodeName[]>();
    public currentUserSubject = new BehaviorSubject<UserDetail>(null);

    constructor(private dataService: DataService, private userService: UserService, private logger: Logger) {
        this.menuRoles = this.dataService.getMenuRoles();
        this.sectorRoles = this.dataService.getSectorRoles();
        this.userService.getCurrentUser().subscribe((user: UserDetail | null) => {
            if (!user) {
                user = { userId: 0 } as UserDetail;
            }
            this.logger.debug("getCurrentUser", user);

            this.simulateUserIfDevMode(user);

            this.currentUserSubject.next(user);
        }, error => {
            this.logger.error(error);
        });

    }

    private simulateUserIfDevMode(user: UserDetail): void {
        if (!isDevMode) return;

        switch (this.debugRole) {
            case DebugRole.Reader:
                user.menuRole = MenuRole.Nobody;
                user.hasWriterAccess = false;
                user.isSuperAdmin = false;
                break;
            case DebugRole.Writer:
                user.menuRole = MenuRole.Writer;
                user.hasWriterAccess = true;
                user.isSuperAdmin = false;
                break;
            case DebugRole.SuperAdmin:
                user.menuRole = MenuRole.SuperAdmin;
                user.hasWriterAccess = true;
                user.isSuperAdmin = true;
                break;

        }

        if (this.debugRole != DebugRole.None) {
            this.logger.debug("simulateUserIfDevMode", this.debugRole, user);
        }

    }

}

export enum DebugRole {
    None = "none",
    Reader = "reader",
    Writer = "writer",
    SuperAdmin = "superAdmin"
}
