<ng-container *ngIf="enabled">
    <mat-expansion-panel [expanded]="expandPanel" (closed)="expandPanel = false" (opened)="expandPanel = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                Add new user
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <user-add></user-add>

    </mat-expansion-panel>
</ng-container>