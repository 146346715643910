import { Component, OnInit } from '@angular/core';
import { UserDetail, UserAction } from 'src/app/models/user.model';
import { Globals } from 'src/app/globals';
import { UserBehaviour } from 'src/app/common';

@Component({
  selector: 'user-edit-panel',
  templateUrl: './user-edit-panel.component.html'
  //styleUrls: ['./user-edit-panel.component.css'],
})
export class UserEditPanelComponent implements OnInit {

  username: string;
  expandPanel: boolean = false;
  currentUser: UserDetail;
  enabled: boolean = false;
  constructor(private globals: Globals, private userBehavior: UserBehaviour) { }

  isMyself(): boolean {
    return this.currentUser ? this.currentUser.username == this.username : false;
  }

  ngOnInit() {
    this.globals.currentUserSubject.subscribe((user: UserDetail) => {
      this.currentUser = user;
    });

    this.userBehavior.usernameSubject.subscribe((username: string) => {
      if (username && username.length > 0) {
        this.username = username;
        this.expandPanel = true;
      }
    });

    this.userBehavior.refreshUsersSubject.subscribe((refresh: boolean) => {
      if (refresh) {
        this.expandPanel = true;
      }
    });

    this.userBehavior.userActionSubject.subscribe((action: UserAction) => {
      const isEditPanel = action == UserAction.Edit || action == UserAction.View;
      this.enabled = isEditPanel;
      if (isEditPanel) {
        this.expandPanel = true;
      }

    });


  }

}