export class SectorTree {
    Data: number;
    Label: string;
    Children: Array<SectorTree>;
    Access: boolean = false;

    Selected: boolean = false;
    Expanded: boolean = false;
}

export class SectorTreeNode {
    data: number;
    label: string;
    access: boolean = false;
    selected: boolean = false;
    children?: SectorTreeNode[];

}

export class SectorFlatNode {
    level: number;
    item: string;
    id: number;
    expandable: boolean;
    hasChild: boolean;
    access: boolean;
    roleId: number;
    roleName: string;
}

export class SelectedSector {
    id: number;
    name: string;
    roleId: number;
    roleName: string;
}

export class SectorAccessRole {
    sectorId: number;
    sectorName: string;
    roleId: number;
}

// export class SectorAcl
// {
//     sectorId: number;
//     hasWriterAccess: boolean;
//     roleId: number;
// }

export class SectorEditNode extends SectorTreeNode {
    level: number;
    path: string;
}

export class Sector {
    Data: number;
    Label: string;
}

export class RegionTree {
    Name: string;
    Id: number;
    ChildRegions: Array<RegionTree>
}

export class Country {
    Id: number;
    Name: string;
    Type: string;
}

export class Company {
    Id: number;
    Name: string;
    Type: string;
    Sector: number;
    isCredit: boolean;
    isEquity: boolean;
    ExistsEquityUnderwork: boolean;
    ExistsCreditUnderwork: boolean;
    UseEquityUnderwork: boolean;
    UseCreditUnderwork: boolean;
}

export class SectorCompanyNode
{
    hierarchy: string;
    sectorLevel: number;
    parentOrder: number;
    sectorName: string;
    sectorId: number;
    accessId: number;
    companyId: number;
    companyName: string;
    protected: boolean;
    exportToWeb: boolean;
    statusId: number;
    statusValue: string;
    statusName: string;
    priceCurrencyId: number;
    priceCurrency: string;
    userCanAccess: boolean;
}

