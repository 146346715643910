import { Injectable } from '@angular/core';
import { DialogBaseComponent, DialogData } from './dialog-base.component';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class ConfirmDialog {

    constructor(private dialog: MatDialog, private snackBar: MatSnackBar) {

    }

    public open(title: string, content: string, callback: (result: any) => void, errorModal: boolean = false): void {
        const dialogRef = this.dialog.open(DialogBaseComponent, {
            width: '400px',
            data: {
                title: title,
                content: content,
                errorModal: errorModal
            }
        });

        dialogRef.afterClosed().subscribe((result: any) => {
            callback(result);
        });
    }

    public openSnackBar(message: string, buttonLabel: string = 'Close', durationInSeconds: number = 10) {
        this.snackBar.open(message, buttonLabel, {
            duration: durationInSeconds * 1000,
            panelClass: 'confirm-snackbar',
            horizontalPosition: 'left',
            verticalPosition: 'bottom'
        });
    }

    public openAppErrorSnackBar(message: string ) {
        const config: MatSnackBarConfig = {
            duration: 120 * 1000,
            panelClass: 'app-error-snackbar',
            horizontalPosition: 'left',
            verticalPosition: 'top',
            politeness: 'assertive'
        }
        this.snackBar.open(message, null, config);
    }

    public openErrorSnackBar(message: string, buttonLabel: string = 'Close') {
        const config: MatSnackBarConfig = {
            duration: 20 * 1000,
            panelClass: 'error-snackbar',
            horizontalPosition: 'left',
            verticalPosition: 'bottom',
            politeness: 'assertive'
        }
        if (buttonLabel == "wait")
        {
            buttonLabel = null;
        }
        this.snackBar.open(message, buttonLabel, config);
    }

    public openError(title: string, content: string) {
        this.open(title, content,
            (result: DialogData) => {
                if (!result) {
                    return;
                }

            }, true);

    }
}
