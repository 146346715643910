import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { SectorEditNode } from 'src/app/models/sector-model';
import { UserDetail, UserAction } from '../models/user.model';
import { UserBehaviour } from '../common/user-behaviour';
import { SectorBehaviour } from '../common/sector-behaviour';
import { ConfirmDialog } from '../common/components';

@Component({
  selector: 'user-section',
  templateUrl: './user-section.component.html',
  styleUrls: ['./user-section.component.sass'],
})
export class UserSectionComponent implements OnInit {
  sectorPanelEnabled = false;
  username: string;
  sectorEditNode: SectorEditNode;
  user: UserDetail;

  title = 'Handelsbanken-GROW-UserAdminWeb';

  constructor(private globals: Globals, 
    private userBehavior: UserBehaviour, 
    private sectorBehaviour: SectorBehaviour,
    private confirmDialog: ConfirmDialog) { }

  ngOnInit() {
    this.globals.currentUserSubject.subscribe(user => {
      if (!user) return;
      if (user.userId > 0) {
        this.user = user;
        this.userBehavior.usernameSubject.subscribe(username => this.username = username);

        this.userBehavior.userActionSubject.subscribe(action =>
          this.sectorPanelEnabled = (action == UserAction.Edit || action == UserAction.View) &&
          this.username && this.username.length > 0);

        this.sectorBehaviour.sectorEditSubject.subscribe((node: SectorEditNode) => this.sectorEditNode = node);

      }
      else 
      {
        this.confirmDialog.openAppErrorSnackBar("Your are not authorized to use this application. Please contact your manager in order to get access");
      }
    });

  }

}
