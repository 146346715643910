import { Validators, FormControl, FormGroup } from '@angular/forms';

export class UserEditForm {

    public form: FormGroup;

    // first 4 characters has to be letters then 2 digits and optionally alphanumeric 
    // characters after for a total length of maximum 10 characters.
    private usernameValidator = Validators.pattern(/^[a-z]{4}\d{2}[a-z,0-9]{0,4}$/);

    public controls = {
        firstName: new FormControl('', [Validators.required]),
        lastName: new FormControl('', [Validators.required]),
        username: new FormControl('', [Validators.required, this.usernameValidator]),
        workPhone: new FormControl('', [Validators.required]),
        mobilePhone: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        inactive: new FormControl(false),
        compliance: new FormControl(false),
        menuRole: new FormControl('')
    };

    public get valid(): boolean {
        return this.form && this.form.valid;
    }
    
    constructor() {
        // const validators = [Validators.required];
        // this.controls.username = new FormControl('', validators);
        this.form = new FormGroup(this.controls);
    }

    
}
