import { BehaviorSubject } from 'rxjs';
import { SectorTreeNode, SectorEditNode, SectorAccessRole, SelectedSector, SectorFlatNode } from '../models/sector-model';
import { Injectable } from '@angular/core';

@Injectable()
export class SectorBehaviour 
{
    public addRoleSubject = new BehaviorSubject<number>(0);
    public sectorEditSubject = new BehaviorSubject<SectorEditNode>(null);
    public treeSubject = new BehaviorSubject<SectorTreeNode[]>([]);
    public sectorAccessRoleSubject = new BehaviorSubject<SectorAccessRole[]>([]);
    public selectedSectorsSubject = new BehaviorSubject<SelectedSector[]>(null);
    public selectedSectorTreeSubject = new BehaviorSubject<SelectedSector[]>([]);
    public appendSectorsSubject = new BehaviorSubject<SelectedSector[]>([]);
    public appendChecklistSelectionSubject = new BehaviorSubject<AppendChecklistSelectionSubject>(null);

}

export class AppendChecklistSelectionSubject
{
    roleId: number;
    sectors: SectorFlatNode[];
    selectedSectors: SelectedSector[]
}
