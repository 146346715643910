import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';

import {
  SectorTree,
  SectorTreeNode,
  SectorAccessRole,
  SectorCompanyNode
} from "../models/sector-model";
import { UpdateSectorAccessRequest } from '../models/sector-update-models';
import { environment } from 'src/environments/environment';

@Injectable()
export class SectorService {
  get baseUrlGrow() {
    return environment.baseUrlGrowApi;
  }

  constructor(private http: HttpClient) {}


  getSectorTree(): Observable<SectorTree> {
    return this.http.get<SectorTree>(this.baseUrlGrow + "v1/sectoraccess/sectortree", {
      withCredentials: true
    });
  }

  getSectorTreeNodeForUser(username: string): Observable<SectorTreeNode[]> {
    return this.http.get<SectorTreeNode[]>(this.baseUrlGrow + "v1/sectoraccess/sectortree/" + username, {
      withCredentials: true
    });
  }

  getSectorUserByUserId(userId: number): Observable<SectorAccessRole[]> {
    return this.http.get<SectorAccessRole[]>(
      `${this.baseUrlGrow}v1/sectoraccess/sectoruser/${userId}`, {
      withCredentials: true
    });
  }

  updateSectorAccess(request: UpdateSectorAccessRequest): Observable<boolean> {
    return this.http.put<boolean>(
      `${this.baseUrlGrow}v1/sectoraccess`, request, { withCredentials: true }
    );
  }

  getSectorCompanyNodes(userId: number, sectorId: number): Observable<SectorCompanyNode[]> {
    return this.http.get<SectorCompanyNode[]>(
      `${this.baseUrlGrow}v1/sectorcompany/${userId}/${sectorId}`, {
      withCredentials: true
    });

  }

  // getSectorTreeForUser(username: string): Observable<SectorTree> {
  //   return this.http.get<SectorTree>(this.baseUrlGrow + "sectoraccess/sectortree/" + username, {
  //     withCredentials: true
  //   });
  //   //.map((res: Response) => res.json())
  // }

  // getSectorCompanies(sectorId: Array<number>): Observable<Company[]> {
  //   let sb = "?";
  //   for (let i = 0; i < sectorId.length; i++) {
  //     if (i != 0 || i < sectorId.length - 1) sb = sb + "&";
  //     sb = sb + "sectors=" + sectorId[i];
  //   }
  //   return this.http.get<Company[]>(
  //     this.baseUrlGrow + "aggregatedreportsectorcompanies/" + sb,
  //     { withCredentials: true }
  //   );
  // }
}
