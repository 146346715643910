<mat-expansion-panel *ngIf="sectorEditNode" [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Sector role
        </mat-panel-title>
        <mat-panel-description>
            <span class="toolbar-spacer"></span>
            <mat-icon class="toolbar-icon" aria-hidden="false" aria-label="Sector role">account_box</mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field class="sector-full-width" [floatLabel]="'always'">
        <input matInput placeholder="Sector name" [value]="sectorEditNode.label" readonly />
    </mat-form-field>

    <br />
    <mat-label>{{sectorEditNode.path}}</mat-label>

    <mat-divider></mat-divider>
    <br />

    <code-name-select [items]="sectorRoles" [label]="'Role'" [selectedItemSubject]="selectedItemSubject"
        [disabled]="!hasWriterAccess"></code-name-select>
    <button *ngIf="hasWriterAccess" mat-icon-button (click)="addRole()" color="primary">
        <mat-icon aria-hidden="false" aria-label="Add role to sector">add_circle</mat-icon>
    </button>

    <br />

</mat-expansion-panel>