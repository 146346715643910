import { Component, Input, OnInit } from "@angular/core";

import { SectorEditNode, SelectedSector } from "../../../models/sector-model";
import { BehaviorSubject } from 'rxjs';
import { Globals } from 'src/app/globals';
import { CodeName } from 'src/app/models/user.model';
import { SectorBehaviour } from 'src/app/common/sector-behaviour';

@Component({
    selector: 'sector-role-access',
    templateUrl: './sector-role-access.component.html',
    styleUrls: ['./sector-role-access.component.sass']
})
export class SectorRoleAccessComponent implements OnInit {

    selectedItemSubject = new BehaviorSubject<CodeName>({ id: 0, name: '' });
    sectorEditNode: SectorEditNode;
    sectorRoles: CodeName[];
    selectedItem: CodeName;
    selectedSectorRole: string;
    hasWriterAccess: boolean;

    constructor(private globals: Globals,
        private sectorBehaviour: SectorBehaviour) {
    }

    ngOnInit() {

        this.globals.currentUserSubject.subscribe(user => this.hasWriterAccess = user && user.hasWriterAccess);

        this.selectedItemSubject.subscribe((item: CodeName) => {
            this.selectedItem = item;
        });

        this.globals.sectorRoles.subscribe(roles => {
            this.sectorRoles = roles;
            const role = this.getSectorRoleByName('Nobody');
            this.setRole(role);
        });

        this.sectorBehaviour.selectedSectorsSubject.subscribe((selectedSectors: SelectedSector[]) => {
            if (!(selectedSectors && selectedSectors.length > 0)) return;

            this.sectorBehaviour.sectorEditSubject.subscribe((node: SectorEditNode) => {
                if (!node) return;
                const sector = selectedSectors.find(s => s.id == node.data);
                const role = sector ? this.getSectorRoleById(sector.roleId) : this.getSectorRoleByName('Nobody');
                this.setRole(role);

            });
        });

        this.sectorBehaviour.sectorEditSubject.subscribe((node: SectorEditNode) => {
            this.sectorEditNode = node;
        });
    }

    setRole(role: CodeName)
    {
        if (role) {
        this.selectedItemSubject.next(role);
        this.selectedSectorRole = role.name;
        }
    }

    getSectorRoleById(id: number): CodeName {
        let role = this.sectorRoles.find(r => r.id == id);
        return role ? role : null;
    }

    getSectorRoleByName(name: string): CodeName {
        let role = this.sectorRoles.find(r => r.name == name);
        return role ? role : null;
    }

    addRole() {
        if (this.selectedItem && this.selectedItem.id > 0) {
            this.sectorBehaviour.addRoleSubject.next(this.selectedItem.id);
        }
    }

}