import { DatePipe } from '@angular/common';

export class DateFormatter
{
    private static datePipe = new DatePipe('en');

    public static ToShortHourMinutes(date: Date | null) {
        if (!date) return '';
        return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm')
    }
    public static ToLongFormatString(date: Date | null) {
        if (!date) return '';
        return this.datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss')
    }
    public static ToShortDate(date: Date | null) {
        if (!date) return '';
        return this.datePipe.transform(date, 'yyyy-MM-dd')
    }
}