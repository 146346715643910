<div class="loading-shade background-gray opacity-50" *ngIf="isLoadingResults">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
</div>

<div class="error-reached" *ngIf="isErrorResults">
    <div class="child">
        <p>Ops, something went wrong. Be so kind and try to retrieve the data again</p>
    </div>
</div>

<mat-expansion-panel [expanded]="expandPanel" (opened)="expandPanel = true" (closed)="expandPanel = false">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Users
        </mat-panel-title>
        <mat-panel-description>
            <span class="toolbar-spacer"></span>
            <mat-icon class="toolbar-icon" aria-hidden="false" aria-label="Users">group</mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <mat-form-field>
        <input matInput (keyup)="applyFilter($event.target.value)">
        <mat-placeholder>Filter</mat-placeholder>
    </mat-form-field>

    <table mat-table [dataSource]="dataSource" multiTemplateDataRows>

        <!-- User Name Column -->
        <ng-container matColumnDef="username">
            <th mat-header-cell *matHeaderCellDef> User name. </th>
            <td mat-cell *matCellDef="let element"> {{element.username}} </td>
        </ng-container>

        <!-- First name Column -->
        <ng-container matColumnDef="firstName">
            <th mat-header-cell *matHeaderCellDef> First name </th>
            <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
        </ng-container>

        <!-- Last name column -->
        <ng-container matColumnDef="lastName">
            <th mat-header-cell *matHeaderCellDef> Last name </th>
            <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
        </ng-container>

        <!-- .table-cell-icon .material-icons
    font-size: 24px
    margin-top: 4px -->

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">
                <mat-icon *ngIf="element.inactive" aria-hidden="false" aria-label="Inactive">block</mat-icon>
            </td>
        </ng-container>

        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="table-header-icon">
                <button mat-icon-button (click)="showAddUser()">
                    <mat-icon aria-hidden="false" color="primary" aria-label="Add new user">add_circle</mat-icon>
                </button>

            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-raised-button *ngIf="currentUser"
                    (click)="emitUsername(element.username)">{{currentUser.isSuperAdmin || isMyself(element.username) ? 'Edit': 'View'}}</button>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>

</mat-expansion-panel>


