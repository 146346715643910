<mat-grid-list *ngIf="hasWriterAccess" cols="2" rowHeight="38px">
    <mat-grid-tile colspan="1">
        <code-name-select [items]="sectorRoles" [label]="''" [selectedItemSubject]="selectedRoleSubject">
        </code-name-select>
        <button type="button" mat-icon-button attr.aria-label="add selected" (click)="addSelected()" color="primary"
            [disabled]="this.checklistSelection.selected.length == 0">
            <mat-icon aria-hidden="false" aria-label="Add selected sectors">add_circle</mat-icon>
        </button>
    </mat-grid-tile>
    <mat-grid-tile colspan="2">
        <div class="tree-button-row">
            <button mat-raised-button color="primary" attr.aria-label="expand all" (click)="toggleNodes()">
                <mat-icon aria-hidden="false" aria-label="toggle nodes">{{expanded ? 'expand_less':
                    'expand_more'}}
                </mat-icon>
            </button>
            <button mat-raised-button color="primary" attr.aria-label="check all" (click)="checkAllNodes()">Check
                all</button>
            <button mat-raised-button color="primary" type="button" attr.aria-label="clear all"
                (click)="clearAllNodes()">Clear all</button>
        </div>
    </mat-grid-tile>
</mat-grid-list>

<div class="sector-access-tree-container">

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="sector-tree">
        <!-- This is the tree node template for leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>
            <mat-checkbox *ngIf="hasWriterAccess && node.access" [checked]="checklistSelection.isSelected(node)"
                [disabled]="hasWriterAccess ? null: 'true'" (change)="sectorItemSelectionToggle(node)">
            </mat-checkbox>
            <a (click)="editSectorRole(node)" routerLink="."
                [class]="node.access ? 'hasSectorAccess' : 'hasNoSectorAccess'">{{node.item}}</a>
        </mat-tree-node>

        <!-- This is the tree node template for expandable nodes -->
        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.item">
                <mat-icon class="mat-icon-rtl-mirror">
                    {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
            </button>

            <div *ngIf="hasWriterAccess && node.access; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <ng-container *ngIf="node.level > 0">
                    <mat-checkbox [checked]="descendantsAllSelected(node)" [disabled]="hasWriterAccess ? null: 'true'"
                        [indeterminate]="descendantsPartiallySelected(node)" (change)="sectorItemSelectionToggle(node)">
                    </mat-checkbox>
                    <a (click)="editSectorRole(node)" routerLink="."
                        [class]="node.access ? 'hasSectorAccess' : 'hasNoSectorAccess'">{{node.item}}</a>
                </ng-container>
                <a *ngIf="node.level == 0" routerLink="." (click)="resetEditNode()"
                    [class]="node.access ? 'hasSectorAccess' : 'hasNoSectorAccess'">{{node.item}}</a>
            </ng-template>
            <ng-template #elseBlock>
                <a *ngIf="node.level > 0" routerLink="." (click)="editSectorRole(node)"
                    [class]="node.access ? 'hasSectorAccess' : 'hasNoSectorAccess'">{{node.item}}</a>
                <span *ngIf="node.level == 0"
                    [class]="node.access ? 'hasSectorAccess' : 'hasNoSectorAccess'">{{node.item}}</span>
            </ng-template>

        </mat-tree-node>
    </mat-tree>
</div>