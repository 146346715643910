<div *ngIf="user" class="user-admin-container">

    <div class="user-list-container">
        <users-list></users-list>
        <user-edit-panel *ngIf="username"></user-edit-panel>
        <user-add-panel></user-add-panel>
    </div>


    <ng-container *ngIf="sectorPanelEnabled">
        <div class="sector-access-container">
            <sector-access></sector-access>
        </div>

        <div class="sector-detail-container">
            <sector-role-access></sector-role-access>
            <selected-sector-tree></selected-sector-tree>
            <sector-companies></sector-companies>
            <!-- <selected-sectors></selected-sectors> -->
        </div>
    </ng-container>

</div>