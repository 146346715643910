import { Component, OnInit, ViewChild } from "@angular/core";
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { UserDetail, UserAction } from 'src/app/models/user.model';
import { UserService } from 'src/app/services';
import { Globals } from 'src/app/globals';
import { UserBehaviour } from 'src/app/common';

@Component({
    selector: 'users-list',
    templateUrl: './users-list.component.html',
    styleUrls: ['./users-list.component.sass'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class UsersListComponent implements OnInit {
    isLoadingResults = true;
    isErrorResults = false;
    expandPanel: boolean = true;
    currentUser: UserDetail;

    columnsToDisplay: string[] = ['username', 'firstName', 'lastName', 'status', 'actions'];
    dataSource: MatTableDataSource<UserDetail> = new MatTableDataSource(new Array<UserDetail>());
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    
    isMyself (username: string) : boolean {
        return this.currentUser ? this.currentUser.username == username : false;
    }
    
    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    constructor(private userService: UserService, private globals: Globals, private userBehavior: UserBehaviour) { }

    ngOnInit(): void {
        this.globals.currentUserSubject.subscribe(user => this.currentUser = user);

        this.userBehavior.refreshUsersSubject.subscribe((refresh: boolean) => {
            if (refresh) {
                this.getUsers();
            }
        });

        this.userBehavior.userActionSubject.subscribe((action: UserAction) => {
            this.expandPanel = action == UserAction.None;
        })

        this.userBehavior.usersSubject.subscribe((users: UserDetail[]) => {
            if (!users || users.length == 0) return;
            let filter = this.dataSource.filter;
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.paginator = this.paginator;
            if (filter)
            {
                this.applyFilter(filter);
            }
            
        });
    }

    getUsers(): void {
        this.userService.getUsers()
            .subscribe((users: UserDetail[]) => {
                this.isLoadingResults = false;
                this.userBehavior.usersSubject.next(users);
            });
    }
    showAddUser(): void {
        this.userBehavior.userActionSubject.next(UserAction.Add);
        this.expandPanel = false;
    }

    emitUsername(value: string) {
        this.userBehavior.usernameSubject.next(value);
        this.userBehavior.userActionSubject.next(UserAction.Edit);
    }

}
