<mat-expansion-panel *ngIf="tree && tree.length > 0" [expanded]="true">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Sector user
        </mat-panel-title>
        <mat-panel-description>
            current access for {{username}}
            <span class="toolbar-spacer"></span>
            <mat-icon class="toolbar-icon" aria-hidden="false" aria-label="Sector access">account_tree</mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <mat-panel-title>
        <b>Add role for selected sectors</b>
    </mat-panel-title>

    <sector-access-tree></sector-access-tree>
</mat-expansion-panel>
