<div *ngIf="user">
    <form class="form" name="userEditForm" [formGroup]="form.form">

        <div class="user-edit-form">
            <mat-form-field>
                <input matInput placeholder="Username" [formControl]="form.controls.username" required
                    [readonly]="readOnlyAttr">
                <user-edit-error [control]="form.controls.username" name="username"></user-edit-error>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="First name" [formControl]="form.controls.firstName" required
                    [readonly]="readOnlyAttr">
                <user-edit-error [control]="form.controls.firstName" name="firstName"></user-edit-error>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Last name" [formControl]="form.controls.lastName" required
                    [readonly]="readOnlyAttr">
                <user-edit-error [control]="form.controls.lastName" name="lastName"></user-edit-error>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Work Phone" [formControl]="form.controls.workPhone" required>
                <user-edit-error [control]="form.controls.workPhone" name="workPhone"></user-edit-error>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Mobile Phone" [formControl]="form.controls.mobilePhone" required>
                <user-edit-error [control]="form.controls.mobilePhone" name="mobilePhone"></user-edit-error>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Enter your email" [formControl]="form.controls.email" required>
                <user-edit-error [control]="form.controls.email" name="email"></user-edit-error>
            </mat-form-field>

            <div *ngIf="isMyself(); then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <input matInput placeholder="Menu role" readonly value="{{roleName}}" name="roleName" id="roleName" />
                <mat-checkbox  disabled [checked]="isComplianceUser()">Compliance admin rights</mat-checkbox>
                <!-- <mat-icon>done</mat-icon>&nbsp;
                <span>Compliance admin rights</span> -->
            </ng-template>
            <ng-template #elseBlock>
                <code-name-select [items]="menuRoles" [selectedItemSubject]="selectedItemSubject" [label]="'Menu role'" style="margin-right: 23px">
                </code-name-select>
                <mat-checkbox id="compliance" color="primary" formControlName="compliance" (click)="toggleCompliance()">Compliance admin rights</mat-checkbox>
            </ng-template>

        </div>

        <mat-divider></mat-divider>

        <div class="meta-data-container">
            <mat-form-field>
                <input matInput [value]="createdBy" disabled>
                <mat-hint>Created by</mat-hint>
            </mat-form-field>

            <mat-form-field>
                <input matInput [value]="changedBy" disabled>
                <mat-hint>Changed by</mat-hint>
            </mat-form-field>
            <br />

            <mat-form-field>
                <input matInput [value]="user.version" disabled>
                <mat-hint>Version</mat-hint>
            </mat-form-field>

            <mat-checkbox id="inactive" color="primary" formControlName="inactive">Inactive user</mat-checkbox>
            <br>
            <mat-checkbox id="invite" color="primary" [formControl]="sendEmailInvite">Send email invite</mat-checkbox>

        </div>

        <br />

        <mat-divider class="form-divider"></mat-divider>
        <br />


        <div class="user-edit-buttons">
            <button type="submit" mat-raised-button (click)="saveUser()" color="primary"
                [disabled]="(form.form.dirty || sendEmailInvite.value === true) || !form.valid ? null: true">Save</button>
            <button type="button" mat-raised-button (click)="cancel()">Cancel</button>
            <button type="button" mat-raised-button (click)="reset()">Undo</button>
        </div>
    </form>
</div>