<ng-container *ngIf="enabled">
    <mat-expansion-panel [expanded]="expandPanel" (closed)="expandPanel = false" (opened)="expandPanel = true">
        <mat-expansion-panel-header>
            <mat-panel-title>
                User Details
            </mat-panel-title>
            <mat-panel-description>
                ({{username}})
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div *ngIf="(currentUser && currentUser.isSuperAdmin) || isMyself(); then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
            <user-edit></user-edit>
        </ng-template>
        <ng-template #elseBlock>
            <user-view></user-view>
        </ng-template>

    </mat-expansion-panel>
</ng-container>