import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface DialogData {
    title: string;
    content: string;
    errorModal: boolean;
}

@Component({
    selector: 'dialog-base',
    templateUrl: 'dialog-base.component.html',
    styleUrls: ['dialog-base.component.sass']
})
export class DialogBaseComponent {

    dialogData: DialogData;
    constructor(
        public dialogRef: MatDialogRef<DialogBaseComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData) { 
            this.dialogData = data;

        }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
