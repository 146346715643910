import { Component, OnInit } from '@angular/core';
import { UserAction } from 'src/app/models/user.model';
import { UserBehaviour } from 'src/app/common';

@Component({
  selector: 'user-add-panel',
  templateUrl: './user-add-panel.component.html'
  //styleUrls: ['./user-add-panel.component.css'],
})
export class UserAddPanelComponent implements OnInit {

  enabled: boolean;
  expandPanel: boolean;
  constructor(private userBehavior: UserBehaviour) { }

  ngOnInit(): void {

    this.userBehavior.userActionSubject.subscribe((action: UserAction) => {
      if (action == UserAction.Add) {
        this.enabled = true;
        this.expandPanel = true;
      }
      else
      {
        this.enabled = false;
      }

    });
  }


}