<mat-expansion-panel [expanded]="false" *ngIf="user && sectorEditNode && companies">
    <mat-expansion-panel-header>
        <mat-panel-title>
            Sector companies
            <ng-container *ngIf="companies.length > 0"> ({{companies.length}})</ng-container>
        </mat-panel-title>
        <mat-panel-description>
            <span class="toolbar-spacer"></span>
            <mat-icon>business</mat-icon>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <div *ngIf="companies.length == 0">
        <mat-label>No companies</mat-label>
    </div>


    <mat-form-field *ngIf="companies && companies.length > 20">
        <input matInput (keyup)="applyFilter($event.target.value)">
        <mat-placeholder>Filter</mat-placeholder>
    </mat-form-field>

    <mat-table *ngIf="companies.length > 0" [dataSource]="dataSource" class="sector-companies-container">
        <!-- Sector name Column -->
        <ng-container matColumnDef="companyName">
            <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span [class]="element.userCanAccess ? null: 'company-disabled'">{{element.companyName}}</span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="protected">
            <mat-header-cell *matHeaderCellDef> Protected </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span [class]="element.userCanAccess ? null: 'company-disabled'">
                    <mat-icon *ngIf="element.protected">done</mat-icon>
                </span>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="roleName">
            <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span [class]="element.userCanAccess ? null: 'company-disabled'">{{element.roleName}}</span>
            </mat-cell>
        </ng-container>
        <!-- <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> Role S/C/U </mat-header-cell>
            <mat-cell *matCellDef="let element">
                <span [class]="element.userCanAccess ? null: 'company-disabled'">{{element.statusValue}}</span>
            </mat-cell>
        </ng-container> -->

        <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
</mat-expansion-panel>