import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { MaterialImportModule } from '../../material/materialimport.module';
import { CommonComponentModule } from '../common/common-component.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataService, SectorService } from '../services';

import {
  SectorAccessComponent,
  SectorAccessTreeComponent,
  SectorRoleAccessComponent,
  SelectedSectorsComponent,
  SelectedSectorTreeComponent,
  SectorCompaniesComponent
} from './components';

@NgModule({
  declarations: [
    SectorAccessComponent,
    SectorAccessTreeComponent,
    SectorRoleAccessComponent,
    SelectedSectorsComponent,
    SelectedSectorTreeComponent,
    SectorCompaniesComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MaterialImportModule,
    CommonComponentModule
  ],
  exports: [
    SectorAccessComponent,
    SectorAccessTreeComponent,
    SectorRoleAccessComponent,
    SelectedSectorsComponent,
    SelectedSectorTreeComponent,
    SectorCompaniesComponent
  ],
  providers: [
    DataService,
    SectorService
  ]
})
export class SectorModule {
}
