import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule, MatCheckbox } from '@angular/material/checkbox';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatTreeModule } from '@angular/material/tree';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';

import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSliderModule } from '@angular/material/slider';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatDividerModule } from '@angular/material/divider';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCardModule } from '@angular/material/card';

//import { DndModule } from 'ng2-dnd';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        MatButtonModule, 
        MatCheckboxModule,
        MatDividerModule,
        MatListModule,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatIconModule,
        MatTooltipModule,
        MatToolbarModule,
        MatChipsModule,
        MatDialogModule,
        MatSliderModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatMenuModule,
        MatExpansionModule,
        MatSidenavModule,
        MatCardModule,
        MatGridListModule,
        MatTreeModule
      ],
      exports: [
        BrowserAnimationsModule,
        MatButtonModule, 
        MatCheckboxModule,
        MatDividerModule,
        MatListModule,
        MatRadioModule,
        MatFormFieldModule,
        MatInputModule,
        MatTableModule,
        MatSortModule,
        MatIconModule,
        MatTooltipModule,
        MatToolbarModule,
        MatChipsModule,
        MatCheckbox,
        MatDialogModule,
        MatSliderModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatMenuModule,
        MatExpansionModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatSlideToggleModule,
        MatCardModule,
        MatGridListModule,
        MatTreeModule,
        MatAutocompleteModule,
        MatLabel,
        DragDropModule
      ],
})
export class MaterialImportModule { }
