import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'user-edit-error',
    templateUrl: './user-edit-error.component.html',
    providers: []
})
export class UserEditErrorComponent {

    @Input("control")
    control: FormControl;
    @Input("name")
    name: string;

    private messages = {
        required: "",
        invalid: "Invalid value",
        userExists: 'The user already exists',
        usernameInvalid: 'Must be 6-10 characters. Starts with 4 letters and then 2 digits, e g "abcd01" or "abcd01abcd etc',
        usernameMaxLength: 'Use up to 10 characters',
        usernameRequired: 'You must enter an username',
        firstNameRequired: 'You must enter a first name',
        lastNameRequired: 'You must enter a last name',
        workPhoneRequired: 'You must enter a work phone number',
        mobilePhoneRequired: 'You must enter a mobile phone number',
        emailRequired: 'You must enter an email address',
        emailInvalid: 'Not a valid email'
    }

    public getErrorMessage() {
        const m = this.messages;
        const c = this.control;
        switch (this.name) {
            case "username":
                if (c.hasError('required')) return m.required;
                if (c.hasError('pattern')) return m.usernameInvalid;
                if (c.hasError('userexists')) return m.userExists;
                if (c.hasError('maxlength')) return `${m.usernameMaxLength} (${c.errors.maxlength.actualLength})`;
                break;
            case "firstName":
                if (c.hasError('required')) return m.required;
                break;
            case "lastName":
                if (c.hasError('required')) return m.required;
                break;
            case "workPhone":
                if (c.hasError('required')) return m.required;
                break;
            case "mobilePhone":
                if (c.hasError('required')) return m.required;
                break;
            case "email":
                if (c.hasError('required')) return m.required;
                if (c.hasError('email')) return m.emailInvalid;
                break;
            default:
                return '';
        }
        return '';
    }
}
