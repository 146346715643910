import { NgModule, CUSTOM_ELEMENTS_SCHEMA, isDevMode, ErrorHandler } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialImportModule } from "../material/materialimport.module";
import { AppComponent } from "./app.component";
import { UserModule } from "./users";
import { SectorModule } from './sectors';
import { Globals } from './globals';
import { UserSectionComponent } from './components';
import { UserBehaviour, SectorBehaviour, CommonComponentModule } from './common';
import { NgxLoggerLevel, LoggerModule } from 'ngx-logger';
import { ApiAuthorizationModule, AuthorizeInterceptor } from 'src/api-authorization';
import { Logger } from './_helpers';
import { UnauthorizedInterceptor } from 'src/api-authorization/unauthorized.interceptor';
import { ErrorHandlerService } from './common/error-handler-service';

@NgModule({
  declarations: [
    AppComponent,
    UserSectionComponent
  ],
  imports: [
    LoggerModule.forRoot({
      serverLoggingUrl: '/api/logs',
      level: isDevMode ? NgxLoggerLevel.DEBUG : NgxLoggerLevel.DEBUG, // Should be OFF in production
      serverLogLevel: NgxLoggerLevel.OFF,
      disableConsoleLogging: isDevMode ? false : false, // Should be true in production
    }),
    AppRoutingModule,
    ApiAuthorizationModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportModule,
    CommonComponentModule,
    HttpClientModule,
    UserModule,
    SectorModule
  ],
  exports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportModule,
    HttpClientModule,
    CommonComponentModule,
    UserModule,
    SectorModule,
    UserSectionComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    Globals,
    UserBehaviour,
    SectorBehaviour,
    Logger,
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
    { provide: ErrorHandler, useClass: ErrorHandlerService }

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
