<div [id]="dialogData.errorModal ? 'error-dialog-container': null">
    <h2 mat-dialog-title>{{dialogData.title}}
        <ng-container *ngIf="dialogData.errorModal">
            <span class="dialog-icon-spacer"></span>
            <i class="material-icons icon-image-preview dialog-icon">error</i>
        </ng-container>
    </h2>
    <mat-dialog-content>
        <div *ngIf="dialogData.errorModal; then thenBlock else elseBlock"></div>
        <ng-template #thenBlock>
            <div class="dialog-icon">
                <span>{{dialogData.content ? dialogData.content : 'Unknown error'}}</span>
            </div>
        </ng-template>
        <ng-template #elseBlock>
            {{dialogData.content ? dialogData.content : 'Are you sure that you want to continue?'}}
        </ng-template>
    </mat-dialog-content>
    <mat-dialog-actions>
        <ng-container *ngIf="!dialogData.errorModal">
            <button mat-button [mat-dialog-close]="true">Yes</button>
            <button mat-button mat-dialog-close>No</button>
        </ng-container>
        <ng-container *ngIf="dialogData.errorModal">
            <button mat-button [mat-dialog-close]="true">Ok</button>
        </ng-container>
    </mat-dialog-actions>
</div>