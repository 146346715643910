import { Component, OnInit } from "@angular/core";
import { SectorFlatNode, SectorTreeNode, SelectedSector, SectorAccessRole, SectorEditNode } from "../../../models/sector-model";
import { Globals } from 'src/app/globals';
import { CodeName, UserDetail, UserEntity } from 'src/app/models/user.model';
import { SectorBehaviour } from 'src/app/common/sector-behaviour';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SectorTreeBase } from '../../sector-tree.base';
import { UserBehaviour } from 'src/app/common';
import { ConfirmDialog } from 'src/app/common/components';
import { UpdateSectorAccessRole, UpdateSectorAccessRequest } from 'src/app/models/sector-update-models';
import { SectorService } from 'src/app/services';
import { Logger } from 'src/app/_helpers';
import { MatTreeFlatDataSource, MatTreeFlattener } from "@angular/material/tree";

@Component({
    selector: 'selected-sector-tree',
    templateUrl: './selected-sector-tree.component.html',
    styleUrls: ['./selected-sector-tree.component.sass']
})
export class SelectedSectorTreeComponent extends SectorTreeBase implements OnInit {

    user: UserEntity;
    selectedNode: SectorEditNode;
    sectorRoles: CodeName[];
    currentUser: UserDetail;
    selectedSectors: SelectedSector[] = [];
    tree: SectorTreeNode[] = [];
    selectedSectorsCopy: SelectedSector[] = [];
    hasWriterAccess: boolean;

    private _transformerSelected = (flatNode: SectorFlatNode, level: number) => {
        const sector = this.selectedSectors.find(n => n.id == flatNode.id);
        if (sector && sector.roleName) {
            flatNode.roleId = sector.roleId;
            flatNode.roleName = sector.roleName;
        }
        return flatNode;
    }

    treeControlSelected = new FlatTreeControl<SectorFlatNode>(node => node.level, node => node.expandable);
    treeFlattenerSelected = new MatTreeFlattener(
        this._transformerSelected, node => node.level, node => node.expandable, node => null);
    dataSourceSelected = new MatTreeFlatDataSource(this.treeControlSelected, this.treeFlattenerSelected);

    constructor(
        public sectorBehaviour: SectorBehaviour,
        public globals: Globals,
        public logger: Logger,
        private sectorService: SectorService,
        private userBehaviour: UserBehaviour,
        private confirmDialog: ConfirmDialog) {
        super(sectorBehaviour, globals, logger);
    }

    ngOnInit() {

        this.globals.currentUserSubject.subscribe((user: UserDetail) => {
            this.currentUser = user;
            this.hasWriterAccess = user && user.hasWriterAccess;
        });

        this.userBehaviour.userEntitySubject.subscribe(user => this.user = user);

        this.globals.sectorRoles.subscribe(roles => this.sectorRoles = roles);

        this.sectorBehaviour.treeSubject.subscribe(tree => {
            if (!(tree && tree.length > 0)) return;
            this.dataSource.data = tree;
            this.selectedSectorsCopy = [];

            this.subscribeToSelectedSectors();
            this.subscribeToAppendSectors();
            this.subscribeToSectorAccessRole();

            this.sectorBehaviour.sectorEditSubject.subscribe(node => this.selectedNode = node);
            this.sectorBehaviour.addRoleSubject.subscribe(id => this.addSectorRole(id));

        });

    }

    subscribeToSelectedSectors() {
        this.sectorBehaviour.selectedSectorsSubject.subscribe((selectedSectors: SelectedSector[]) => {
            if (!selectedSectors) return;
            this.selectedSectors = selectedSectors;
            if (this.selectedSectors.length > 0) {
                this.logger.debug("selectedSectorsSubject", selectedSectors);

                this.cloneSelectedSectorsForUndo();

                const sectors = this.getSelectedDescendants(selectedSectors);
                this.logger.debug("sectors", sectors);
                this.dataSourceSelected.data = sectors;
            }
            else {
                this.dataSourceSelected.data = [];
            }
            this.treeControlSelected.expandAll();

        });
    }


    subscribeToAppendSectors() {
        this.sectorBehaviour.appendSectorsSubject.subscribe((appendSectors: SelectedSector[]) => {
            if (appendSectors.length == 0) return;

            const selectedSectors = this.selectedSectors.filter(selected => !appendSectors.find(s => s.id == selected.id));
            const toAppend = appendSectors.filter(sector => !selectedSectors.find(s => s.id == sector.id));
            selectedSectors.push(...toAppend);

            this.sectorBehaviour.selectedSectorsSubject.next(selectedSectors);

        })
    }

    subscribeToSectorAccessRole() {
        this.sectorBehaviour.sectorAccessRoleSubject.subscribe((sectors: SectorAccessRole[]) => {

            this.globals.sectorRoles.subscribe(roles => {
                if (!this.sectorRoles) {
                    this.sectorRoles = roles;
                }
                const selectedSectors = [];
                for (let sector of sectors) {
                    const selectedSector: SelectedSector = {
                        id: sector.sectorId,
                        name: sector.sectorName,
                        roleId: sector.roleId,
                        roleName: this.getSectorRoleById(sector.roleId).name
                    };
                    selectedSectors.push(selectedSector);
                }
                this.sectorBehaviour.selectedSectorsSubject.next(selectedSectors);
            })

        });
    }

    getNodeText(node: SectorFlatNode): string {
        if (!node) return '';
        return node.roleName && node.roleName.length > 0 ? `${node.item} (${node.roleName})` : `${node.item}`;
    }

    addSectorRole(roleId: number) {
        if (!(roleId && this.selectedNode && this.selectedNode.data)) return;

        this.logger.debug("addSectorRole", roleId);
        let role = this.getSectorRoleById(roleId);

        const selectedSectors = [{ id: this.selectedNode.data, name: this.selectedNode.label, roleId: roleId, roleName: role.name }];
        this.sectorBehaviour.appendSectorsSubject.next(selectedSectors);

    }

    updateDatasource(selectedSectors: SelectedSector[]) {
        this.sectorBehaviour.selectedSectorsSubject.next(selectedSectors);
    }

    removeSectorAccess(sectorId: number) {
        this.logger.debug("removeSectorAccess", sectorId);
        const idx = this.selectedSectors.findIndex(v => v.id == sectorId);
        if (idx > -1) {
            this.selectedSectors.splice(idx, 1);
            const node = this.treeControlSelected.dataNodes.find(n => n.id == sectorId);
            node.roleId = 0;
            node.roleName = null;
            this.updateDatasource(this.selectedSectors);
        }
        return false;
    }

    undo() {
        if (this.selectedSectorsCopy && this.selectedSectorsCopy.length > 0) {
            this.logger.debug("undo", this.selectedSectorsCopy);
            const cloned = this.cloneSelectedSectors(this.selectedSectorsCopy);
            this.sectorBehaviour.selectedSectorsSubject.next(cloned);
        }
    }

    cloneSelectedSectors(sectors: SelectedSector[]): SelectedSector[] {
        const cloned = [];
        for (let selectedSector of sectors) {
            const clone: SelectedSector = Object.create(selectedSector);
            cloned.push(clone);
        }
        return cloned;
    }

    cloneSelectedSectorsForUndo() {
        if (this.selectedSectorsCopy.length > 0) return;
        this.logger.debug("cloning", this.selectedSectors);
        this.selectedSectorsCopy = this.cloneSelectedSectors(this.selectedSectors);
    }

    clear() {
        this.updateDatasource([]);
    }

    save() {
        this.confirmDialog.open("Save sector access", "Are you sure that you want update with the selected sectors?", (success) => {
            if (success) {
                this.saveToDb();
            }
        })
    }

    saveToDb(): void {
        const u = this.user;
        this.logger.debug("saveToDb user?", this.user ? true : false);
        if (!u) return;


        const sectors: UpdateSectorAccessRole[] = this.selectedSectors.map((s: SelectedSector) => {
            return { sectorId: s.id, roleId: s.roleId };
        });

        const request: UpdateSectorAccessRequest =
        {
            userId: u.userId,
            sectors: sectors
        };

        this.logger.debug("saveToDb request", request);

        this.sectorService.updateSectorAccess(request)
            .subscribe(success => {
                if (success) {
                    this.confirmDialog.openSnackBar('Updated sector access information');
                    //this.closeSectorRolePanelIfNotFound();
                }
                else {
                    this.confirmDialog.openErrorSnackBar('Could not update sector access information');
                }
            }, () => {
                this.confirmDialog.openErrorSnackBar('Unknown error. Could not update sector access information');
            });
    }

    // closeSectorRolePanelIfNotFound()
    // {
    //     if (this.selectedNode)
    //     {
    //         const sector = this.selectedSectors.find(s => s.id == this.selectedNode.data);
    //         if (!sector) {
    //             this.sectorBehaviour.sectorEditSubject.next(null);
    //         }
    //     }        
    // }

    getSectorRoleById(id: number): CodeName {
        if (!this.sectorRoles) return null;
        let role = this.sectorRoles.find(r => r.id == id);
        return role ? role : null;
    }

    getSectorRoleByName(name: string): CodeName {
        let role = this.sectorRoles.find(r => r.name == name);
        return role ? role : null;
    }

}