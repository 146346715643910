import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserAction, UserDetail, UserEntity, ComplianceUser } from '../models/user.model';

@Injectable()
export class UserBehaviour 
{
    public refreshUsersSubject = new BehaviorSubject<boolean>(true);
    public usernameSubject = new BehaviorSubject<string>('')
    public userActionSubject = new BehaviorSubject<UserAction>(UserAction.None);
    public usersSubject = new BehaviorSubject<UserDetail[]>([]);
    public userEntitySubject = new BehaviorSubject<UserEntity>(null);
    //public complianceUserSubject = new BehaviorSubject<ComplianceUser>(null);

}

