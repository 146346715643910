import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { CodeName, UserEntity, MenuRole, UserAction } from 'src/app/models/user.model';
import { UserService } from 'src/app/services';
import { Globals } from 'src/app/globals';
import { ConfirmDialog, DialogData } from 'src/app/common/components';
import { UserBehaviour } from 'src/app/common';
import { AddUserRequest } from 'src/app/models/user-update-models';
import { UserEditForm } from 'src/app/models/form-models';
import { UserValidator } from 'src/app/models/user-validator';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { UserInviteModel } from "src/app/models/user-invite-model";
import { UserUpdateModel } from "src/app/models/user-update-model";
import { FormControl } from '@angular/forms';

@Component({
    selector: 'user-add',
    templateUrl: './user-add.component.html',
    styleUrls: ['./user-add.component.sass']
})
export class UserAddComponent implements OnInit {
    sendEmailInvite = new FormControl(true);
    selectedItemSubject: BehaviorSubject<CodeName>;

    menuRoles: CodeName[];
    user = new UserEntity();

    datePipe = new DatePipe('en');
    durationInSeconds = 5;
    errorDurationInSeconds = 30;

    form = new UserEditForm();

    constructor(private userService: UserService,
        private auth: AuthorizeService,
        private globals: Globals,
        private confirmDialog: ConfirmDialog,
        private userBehavior: UserBehaviour) { }

    ngOnInit(): void {
        this.globals.menuRoles.subscribe((roles: CodeName[]) => {
            this.menuRoles = roles;
            const defaultMenuRole = this.getMenuRole(MenuRole.Nobody);
            this.selectedItemSubject = new BehaviorSubject<CodeName>(defaultMenuRole);
            this.form.controls.menuRole.setValue(defaultMenuRole.id.toString());
            this.selectedItemSubject.subscribe(item => this.handleSelectedItem(item))
        });
    }

    public get roleName(): string {
        const roleId = this.form.controls.menuRole.value;
        if (!this.menuRoles || this.menuRoles.length === 0) return '';
        if (!roleId) return '';
        const role = this.menuRoles.find(r => this.form.controls.menuRole.value == r.id.toString())
        return role ? role.name : '';
    }

    syncWithSSO(): void {
        const u_model: UserUpdateModel = {
            Email: this.user.email,
            Roles: [this.roleName],
            SystemId: 'GROW',
          };
        this.userService.syncSSO( u_model, this.auth.getSSOUpdatePath()).subscribe(()  => { },
        _err => {
            this.confirmDialog.openErrorSnackBar(`Could not sync with identityserver: ${this.user.username}`);
        })
    }

    sendInviteEmail(): void{
        const invite_model: UserInviteModel = {
            ActivateLabel: 'Activate account',
            ContactEmail: 'research.online@handelsbanken.se',
            Header: 'Welcome to GROW',
            ReturnUrl: 'https://www.researchonline.se/',
            Subject: 'Welcome to GROW',
            Username: this.user.email,
            ApplicationName: "GROW" // Do not change
          };
        this.userService.sendInviteEmail(invite_model, this.auth.getInviteEmailPath()).subscribe(() => { },
            _err => {
                this.confirmDialog.openErrorSnackBar(`Could not send email to ${this.user.email}`);
            });
    }
    
    updateUserFromForm() {
        const form = this.form.controls;
        const u = this.user;
        u.email = form.email.value;
        u.firstName = form.firstName.value;
        u.fullName = form.firstName.value + ' ' + form.lastName.value;
        u.lastName = form.lastName.value;
        u.mobilePhone = form.mobilePhone.value;
        u.workPhone = form.workPhone.value;
        u.menuRole = parseInt(form.menuRole.value);
        u.username = form.username.value;
    }

    userExists(): boolean {
        const username = this.form.controls.username.value;
        return UserValidator.find(username);
    }

    addUser(): boolean {
        if (!this.form.valid) {
            return;
        }

        if (this.userExists()) {
            this.form.controls.username.setErrors({ userexists: true });
            this.confirmDialog.openError('User exists', `User already exists: ${this.form.controls.username.value}`);
            return;
        }

        this.confirmDialog.open('Add user', 'Are you sure that you want to add the user?',
            (result: DialogData) => {
                if (!result) {
                    return;
                }

                this.updateUserFromForm();
                this.saveToDb();
                this.syncWithSSO();
                if (this.sendEmailInvite.value === true) {
                    this.sendInviteEmail();
                }

            }
        )

        return false;
    }

    saveToDb(): void
    {
        const u = this.user;
        const request: AddUserRequest = 
        {
            username: u.username,
            firstName: u.firstName,
            lastName: u.lastName,
            email: u.email,
            workPhone: u.workPhone,
            mobilePhone: u.mobilePhone,
            menuRole: u.menuRole
        };

        this.userService.addUser(request)
            .subscribe((newUser: UserEntity) => {
                if (newUser)
                {
                    this.confirmDialog.openSnackBar(`Added user: ${this.user.fullName} (${this.user.username})`);
                    this.userBehavior.refreshUsersSubject.next(true);
                    this.userBehavior.usernameSubject.next(this.user.username);
                    this.userBehavior.userActionSubject.next(UserAction.Edit);
                }
                else
                {
                    this.confirmDialog.openErrorSnackBar(`Could not add user: ${this.user.fullName} (${this.user.username})`);
                }
            });
    }

    cancel() {
        this.userBehavior.userActionSubject.next(UserAction.None);
    }

    getMenuRole(menuRole: MenuRole): CodeName {
        let role = this.menuRoles.find(r => r.name == menuRole.toString());
        return role ? role : null;
    }

    handleSelectedItem(menuRole: CodeName) {
        const id = menuRole.id;
        if (this.user && id != this.user.menuRole) {
            this.form.controls.menuRole.setValue(id);
            this.form.controls.menuRole.markAsDirty();
            this.user.menuRole = id;
            this.updateUserFromForm();
        }
    }


    
}

