import { NgModule } from "@angular/core";
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialImportModule } from '../../material/materialimport.module';
import { CodeNameSelectComponent,DialogBaseComponent, ConfirmDialog } from './components';

@NgModule({
  declarations: [
    CodeNameSelectComponent,
    DialogBaseComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportModule
  ],

  exports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialImportModule,
    CodeNameSelectComponent,
    DialogBaseComponent
  ],
  entryComponents: [DialogBaseComponent],
  providers: [ConfirmDialog]
  
  
})
export class CommonComponentModule { }
