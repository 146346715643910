import { SectorBehaviour } from '../common/sector-behaviour';
import { SectorFlatNode, SelectedSector, SectorTreeNode } from '../models/sector-model';
import { FlatTreeControl } from '@angular/cdk/tree';
import { CodeName } from '../models/user.model';
import { SelectionModel } from '@angular/cdk/collections';
import { Globals } from '../globals';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { Logger } from '../_helpers';

export class SectorTreeBase {

    flatNodeMap = new Map<SectorFlatNode, SectorTreeNode>();

    nestedNodeMap = new Map<SectorTreeNode, SectorFlatNode>();

    treeControl = new FlatTreeControl<SectorFlatNode>(node => node.level, node => node.expandable);

    // private _transformer = (node: SectorTreeNode, level: number) => {
    //     let flatNode = {
    //         level: level,
    //         item: node.label,
    //         expandable: node.children && node.children.length > 0
    //     };
    //     this.setNodeItem(flatNode, node);
    //     return flatNode;
    // }


    private _transformer = (node: SectorTreeNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.id === node.data
            ? existingNode
            : new SectorFlatNode();
        flatNode.item = node.label;
        flatNode.level = level;
        flatNode.id = node.data;
        flatNode.access = node.access;
        flatNode.expandable = node.children && node.children.length > 0;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    treeFlattener = new MatTreeFlattener(
        this._transformer, node => node.level, node => node.expandable, node => node.children);

    dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

    /** The selection for checklist */
    checklistSelection = new SelectionModel<SectorFlatNode>(true /* multiple */);

    constructor(public sectorBehaviour: SectorBehaviour, public globals: Globals, public logger: Logger) { }

    getLevel = (node: SectorFlatNode) => node.level;

    isExpandable = (node: SectorFlatNode) => node.expandable;

    getChildren = (node: SectorTreeNode): SectorTreeNode[] => node.children;

    hasChild = (_: number, _nodeData: SectorFlatNode) => _nodeData.expandable;
    hasDescendants = (_: number, _nodeData: SectorFlatNode) => this.treeControl.getDescendants(_nodeData).length > 0 ? true : false;

    hasNoContent = (_: number, _nodeData: SectorFlatNode) => _nodeData.item === '';    
    
    sortSectorFn = (n1: SectorFlatNode, n2: SectorFlatNode): number => n1.level - n2.level;

    getSelected(role: CodeName): SelectedSector[] {
        const checkedNodes = this.getSelectedFlatNodes(this.checklistSelection.selected);
        const sectorTreeNodes = this.getNodeItems(checkedNodes);
        const selectedNodes: SelectedSector[] = sectorTreeNodes
            .filter(s => s.access)
            .map(node => {
                return {
                    id: node.data,
                    name: node.label,
                    roleId: role.id,
                    roleName: role.name
                };
            })
        return selectedNodes;
    }

    getSelectedFlatNodes(selection: SectorFlatNode[]): SectorFlatNode[] {
        const checkedNodes: SectorFlatNode[] = [];
        for (const node of selection.sort(n => n.level).reverse()) {
            const parent = this.getParentNode(node, 2);
            if (node.level > 0 && !(parent && this.descendantsAllSelected(parent))) {
                checkedNodes.push(node);
            }
        }
        return checkedNodes;
    }
    
    getSelectedDescendants(selectedNodes: SelectedSector[]): SectorFlatNode[] {

        const sectors: SectorFlatNode[] = [];

        for(const flatNode of this.treeControl.dataNodes)
        {
            const selectedNode = selectedNodes.find(s => s.id == flatNode.id);
            if (!selectedNode) continue;
            if (!sectors.find(n => n.id == selectedNode.id)) {

                const nodes = this.addSelectedParents(flatNode, []).sort(this.sortSectorFn);
                for (const sector of nodes) {
                    if (!sectors.find(n => n.id == sector.id)) {
                        sectors.push(sector);
                    }
                }
            }
        }

        // for (const selectedNode of selectedNodes) {
        //     if (!sectors.find(n => n.id == selectedNode.id)) {
        //         const flatNode = this.treeControl.dataNodes.find(n => n.id == selectedNode.id);
        //         const nodes = this.addSelectedParents(flatNode, []).sort(this.sortSectorFn);
        //         for (const sector of nodes) {
        //             if (!sectors.find(n => n.id == sector.id)) {
        //                 sectors.push(sector);
        //             }
        //         }
        //     }
        // }
        return sectors;
    }

    addSelectedParents(node: SectorFlatNode, sectors: SectorFlatNode[]): SectorFlatNode[] {
        while (node) {
            if (!sectors.find(n => n.id == node.id)) {
                sectors.push(node);
                const parent = this.getParentNode(node);
                return this.addSelectedParents(parent, sectors);
            }
        }
        return sectors;
    }

// setSelected(sectors: SectorAccessRole[]) {
    //     if (!sectors) return;

    //     this.clearAllNodes();

    //     console.log("setSelected", sectors);
    //     for (let sector of sectors) {
    //         let node = this.treeControl.dataNodes.find(n => n.id == sector.sectorId);
    //         if (!node) continue;
    //         //console.log("Found node", sector);
    //         this.checklistSelection.select(node);
    //         let descendants = this.treeControl.getDescendants(node);
    //         if (!descendants) continue;
    //         this.checklistSelection.select(...descendants);
    //     }
    // }

    // addChecklistSelection(selectedNodes: SelectedSector[]) {
    //     const subject: AppendChecklistSelectionSubject = {
    //         roleId: this.selectedRole.id,
    //         selectedSectors: selectedNodes,
    //         sectors: this.getSelectedDescendants(selectedNodes)

    //     };
    //     // console.log("checklistSelection.selected", this.checklistSelection.selected);
    //     // console.log("addChecklistSelection", subject);
    //     this.sectorBehaviour.appendChecklistSelectionSubject.next(subject);
    // }

    // getSelectedDescendants(selectedNodes: SelectedSector[]): SectorFlatNode[] {

    //     const sectors: SectorFlatNode[] = [];

    //     for (const selectedNode of selectedNodes) {
    //         if (!sectors.find(n => n.id == selectedNode.id)) {
    //             const node = this.treeControl.dataNodes.find(n => n.id == selectedNode.id);
    //             const nodes = this.addSelectedParents(node, []).sort(this.sortSectorFn);
    //             for (const sector of nodes) {
    //                 if (!sectors.find(n => n.id == sector.id)) {
    //                     sectors.push(sector);
    //                 }
    //             }
    //         }
    //     }

    //     console.log(sectors);
    //     return sectors;
    // }

    // addSelectedParents(node: SectorFlatNode, sectors: SectorFlatNode[]): SectorFlatNode[] {
    //     while (node) {
    //         if (!sectors.find(n => n.id == node.id)) {
    //             sectors.push(node);
    //             const parent = this.getParentNode(node);
    //             return this.addSelectedParents(parent, sectors);
    //         }
    //     }
    //     return sectors;
    // }

    
    getParentNode(node: SectorFlatNode, minLevel: number = 1): SectorFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < minLevel) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    getNodePath(node: SectorFlatNode, paths: Array<string>): string {
        while (node) {
            paths.push(node.item);
            const parent = this.getParentNode(node);
            return this.getNodePath(parent, paths);
        }

        return `/${paths.reverse().join('/')}`;
    }

    setNodeItem(flatNode: SectorFlatNode, node: SectorTreeNode) {
        this.flatNodeMap.set(flatNode, node);
    }


    getNodeItem(node: SectorFlatNode): SectorTreeNode {
        return this.flatNodeMap.get(node);
    }

    getNodeItems(flatNodes: SectorFlatNode[]): SectorTreeNode[] {
        const treeNodes = [];
        for (let flatNode of flatNodes) {
            let treeNode = this.flatNodeMap.get(flatNode);
            treeNodes.push(treeNode);
        }
        return treeNodes;
    } 
    
    descendantsHasAccess(node: SectorFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descHasAccess = descendants.filter(child =>
            child.access == true
        );

        return descHasAccess.length > 0;
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: SectorFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );

        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: SectorFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    sectorItemSelectionToggle(node: SectorFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        if (descendants) {
            this.checklistSelection.isSelected(node)
                ? this.checklistSelection.select(...descendants)
                : this.checklistSelection.deselect(...descendants);

            // Force update for the parent
            descendants.every(child =>
                this.checklistSelection.isSelected(child)
            );
        }
        this.checkAllParentsSelection(node);
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    sectorLeafItemSelectionToggle(node: SectorFlatNode): void {
        this.checklistSelection.toggle(node);
        this.checkAllParentsSelection(node);
    }

    /* Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: SectorFlatNode): void {
        let parent: SectorFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: SectorFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.every(child =>
            this.checklistSelection.isSelected(child)
        );
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }
}