import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { CodeName, UserEntity, ComplianceUser } from 'src/app/models/user.model';
import { UserService } from 'src/app/services';
import { UserBehaviour, DateFormatter } from 'src/app/common';

@Component({
    selector: 'user-view',
    templateUrl: './user-view.component.html',
    styleUrls: ['./user-view.component.sass']
})
export class UserViewComponent implements OnInit {

    menuRoles: CodeName[];
    user: UserEntity;
    isComplianceUser: boolean;
    username: string;
    selectedMenuRole: string;

    public get createdBy(): string {
        if (!(this.user && this.user.createdBy)) return '';

        return `${this.user.createdBy} ${DateFormatter.ToShortHourMinutes(this.user.createdDate)}`
    }

    public get changedBy(): string {
        if (!this.user) return '';

        return `${this.user.changedBy} ${DateFormatter.ToShortHourMinutes(this.user.changedDate)}`
    }

    constructor(private userService: UserService, private globals: Globals, private userBehaviour: UserBehaviour) { }

    ngOnInit(): void {
        this.globals.menuRoles.subscribe(roles => {
            this.menuRoles = roles;
            this.userBehaviour.usernameSubject.subscribe((username: string) => {
                this.username = username;
                if (this.username && this.username.length > 0) {
                    this.getUser();
                }
            });

        });

    }

    getUser() {
        this.userService.getUser(this.username)
            .subscribe(user => {
                this.user = user;
                this.userBehaviour.userEntitySubject.next(user);
                this.setSelectedMenuRole();
                this.getComplianceUser();
            });
    }

    setSelectedMenuRole() {
        var role = this.menuRoles.find(r => r.id == this.user.menuRole);
        this.selectedMenuRole = role ? role.name : "Not set";
    }

    getComplianceUser() {
        this.userService.getComplianceUser(this.user.userId)
            .subscribe((complianceUser: ComplianceUser) => {
                this.isComplianceUser = complianceUser && complianceUser.id > 0;
            });
    }

}

