import { Component, OnInit } from '@angular/core';
import { SectorTreeNode, SectorEditNode, SectorFlatNode, SelectedSector } from '../../../models/sector-model';
import { SectorBehaviour } from 'src/app/common/sector-behaviour';
import { CodeName, UserDetail } from 'src/app/models/user.model';
import { BehaviorSubject } from 'rxjs';
import { Globals } from 'src/app/globals';
import { SectorTreeBase } from '../../sector-tree.base';
import { Logger } from 'src/app/_helpers';

@Component({
    selector: 'sector-access-tree',
    templateUrl: './sector-access-tree.component.html',
    styleUrls: ['./sector-access-tree.component.sass']
})
export class SectorAccessTreeComponent extends SectorTreeBase implements OnInit {

    sectorRoles: CodeName[];
    selectedRoleSubject = new BehaviorSubject<CodeName>({ id: 0, name: '' });
    selectedRole: CodeName;
    currentUser: UserDetail;
    expanded: boolean = false;
    hasWriterAccess: boolean = false;

    constructor(public sectorBehaviour: SectorBehaviour, public globals: Globals, public logger: Logger) {
        super(sectorBehaviour, globals, logger);
    }

    ngOnInit() {

        this.sectorBehaviour.treeSubject.subscribe((tree: SectorTreeNode[]) => {
            if (tree && tree.length > 0) {
                this.dataSource.data = tree;

                this.globals.currentUserSubject.subscribe((user: UserDetail) => {
                    this.currentUser = user;
                    this.hasWriterAccess = user && user.hasWriterAccess;
                });
            }
        });

        this.selectedRoleSubject.subscribe((item: CodeName) => {
            this.selectedRole = item;
        });

        this.globals.sectorRoles.subscribe(roles => {
            this.sectorRoles = roles;
            let role = this.getSectorRoleByName('Nobody');
            if (role) {
                this.selectedRoleSubject.next(role);
            }
        });

    }

    resetEditNode() {
        this.sectorBehaviour.sectorEditSubject.next(null);
    }

    checkAllNodes() {
        for (let i = 0; i < this.treeControl.dataNodes.length; i++) {
            this.checklistSelection.select(this.treeControl.dataNodes[i]);
        }
    }

    clearAllNodes() {
        this.checklistSelection.clear();
    }

    toggleNodes() {

        if (this.expanded) {
            this.treeControl.collapseAll();
        }
        else {
            this.treeControl.expandAll();
        }
        this.expanded = !this.expanded;
    }

    expandAllNodes() {
        this.treeControl.expandAll();
    }

    collapseAllNodes() {
        this.treeControl.collapseAll();
    }

    addSelected() {
        const selectedNodes = this.getSelected();
        this.logger.debug("sector-access-tree::addSelected", selectedNodes);
        this.sectorBehaviour.appendSectorsSubject.next(selectedNodes);

        //this.addChecklistSelection(selectedNodes);

    }
    getSelected(): SelectedSector[] {
        return super.getSelected(this.selectedRole);
    }

    getSectorRoleByName(name: string): CodeName {
        let role = this.sectorRoles.find(r => r.name == name);
        return role ? role : null;
    }

    editSectorRole(flatNode: SectorFlatNode) {
        const node: SectorTreeNode = this.getNodeItem(flatNode);
        const editNode = node as SectorEditNode;
        editNode.level = flatNode.level;
        editNode.path = this.getNodePath(flatNode, []);
        this.sectorBehaviour.sectorEditSubject.next(editNode);
    }

}